.skeleton {
    background-color: #ddd;
    margin: 12px 0;
}

.skeleton.text {
    width: 100%;
    height: 11px;
    border-radius: 5px;
}

.skeleton.Title {
    width: 97%;
    height: 23px;
    margin-bottom: 18px;
    border-radius: 4px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.minsize-avatar {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-top: 3px;
}

.skeleton.thumbnail {
    width: 100%;
    height: 180px;
    margin-bottom: 16px;
}

.skeleton_emp {
    width: 1050px;
    height: auto;
    margin: 1.3rem auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    justify-content: space-between;
}

.skeleton-wrapper {
    margin-bottom: 25px;
    width: 30%;
    padding: 14px 20px;
    height: 370px;
    overflow: hidden;
    position: relative;
}

.skeleton-story {
    position: relative;
}

@media only screen and (max-width: 1100px) {
    .skeleton_emp {
        width: 100%;
        padding: 0 1.7rem;
    }
    .skeleton-wrapper {
        width: 45%;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 650px) {
    .skeleton_emp {
        width: 100%;
    }
    .skeleton-wrapper {
        width: 100%;
    }
    .skeleton-story {
        width: 350px;
        margin: 0 auto;
    }
}

.skeleton-wrapper.light {
    background-color: #f8f8f8;
}

.skeleton-wrapper.dark {
    background-color: #444;
}

.skeleton-wrapper .skeleton {
    background-color: rgb(228, 228, 228);
}


/* animation effects  */

.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading infinite 1.2s;
}

.shimmer {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: skew(-6deg);
}

@keyframes loading {
    0% {
        transform: translateX(-150%)
    }
    50% {
        transform: translateX(-10%)
    }
    100% {
        transform: translateX(150%)
    }
}