.Inclusive-changePass-page {
    position: relative;
    width: 100vw;
    height: 76vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Inclusive-changePass-page form {
    padding: 1.2rem;
    height: 420px;
    width: 450px;
}

.Inclusive-changePass-page form .input-wrapper {
    position: relative;
    margin-bottom: 15px;
    padding: 6px;
}

.Inclusive-changePass-page form .input-wrapper svg {
    position: absolute;
    right: 20px;
    top: 26px;
    font-size: 1.7rem;
    color: #8b8b8b;
    cursor: pointer;
}

.Inclusive-changePass-page form .input-wrapper input {
    outline: 0;
    width: 100%;
    border: #d1d1d1 solid 1px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #252525;
    padding: 24px 0 16px 16px;
}

.Inclusive-changePass-page form .input-wrapper label {
    position: absolute;
    top: -5px;
    left: 14px;
    font-size: 0.8rem;
    font-family: 'Inter', sans-serif;
    background-color: white;
    color: #646464;
    padding: 0 8px;
}

.Inclusive-changePass-page form input:focus-within {
    border: #0205b1 solid 1px;
}

.Inclusive-changePass-page form input:focus+label {
    color: #0205b1 !important;
}

.updatePass-btn {
    width: 100%;
    background-color: #0063a5;
    padding: 0.7rem;
    color: white;
    margin-top: 1.2rem;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
}

.Inclusive-changePass-page form .error_msg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    background-color: rgba(245, 72, 72, 0.9);
    color: white;
    padding: 8px;
    font-size: 0.9rem;
    text-align: center;
}

.Inclusive-changePass-page form .success_msg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    background-color: rgba(14, 218, 75, 0.9);
    color: white;
    padding: 8px;
    font-size: 0.9rem;
    text-align: center;
}

.Inclusive-changePass-page form .success_msg a {
    color: black;
    font-weight: bold;
    padding-left: 1rem;
}