  .team-section {
    text-align: center;
  }
  
  .team-header {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .team-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
  }
  
  .team-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    margin: 10px;
    text-align: center;
  }
  
  .team-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .team-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .team-title {
    font-size: 1rem;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .team-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .team-card {
      width: 80%;
    }
  }
