
.articles-section {
  text-align: center;
  margin-top: 50px;
}

.section-header {
  margin-bottom: 40px;
}

.section-category {
  font-size: 1rem;
  color: #f5a623;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.articles-container {
  display: flex;
  margin-left: 2vh;
  margin-right: 2vh;
}

.article-card {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: "90%";
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease;
}

.article-card:hover {
  transform: translateY(-10px);
}

.article-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-content {
  text-align: left;
}

.article-title {
  font-size: 1.3rem;
  color: #007bff;
  margin-bottom: 10px;
}

.article-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.article-link {
  font-size: 1rem;
  color: #f5a623;
  text-decoration: none;
}

.article-link:hover {
  text-decoration: underline;
}
